<template>
  <div>  
    <b-row>
      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-tools">
          </div>
          <h6 class="title-tools">{{$t('creator.titleActiveTodos')}}</h6>
          <h2>{{utils.numberFormat(active_proposals)}}</h2>
          <p class="text-muted m-0">{{utils.numberFormat(waiting_buyer)}} {{$t('creator.waitingBuyer')}}</p>
        </b-card>
      </b-col>

      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-orders">
          </div>
          <h6 class="title-orders">{{$t('creator.titleNewOrders')}}</h6>
          <h2>{{utils.numberFormat(new_orders)}}</h2>
          <p class="text-muted m-0">{{utils.numberFormat(new_collabs)}} {{$t('creator.activeOrders')}}</p>
        </b-card>
      </b-col>

      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-requests">
          </div>
          <h6 class="title-admin-changes">{{$t('creator.titleAdminChange')}}</h6>
          <h2>{{utils.numberFormat(changes_required)}}</h2>
          <p class="text-muted m-0">{{utils.numberFormat(in_review)}} {{$t('creator.textInAdmin')}}</p>
        </b-card>
      </b-col>

      <b-col class="col-12 col-md-6 col-lg-3 d-flex align-items-stretch">
        <b-card class="overflow-hidden card-shadow">
          <div class="background-left-proposal default-color-background-earnings">
          </div>
          <h6 class="title-potential">{{$t('creator.titlePotential')}}</h6>
          <h2>$ {{utils.numberFormat(potential_earnings, true)}}</h2>
          <p class="text-muted m-0">$ {{utils.numberFormat(pending_paid, true)}} {{$t('creator.pedingPayout')}}</p>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="info_table.length > 0">
      <b-col class="col-12">
        <b-card class="table-responsive" id="table-searcher-card">
          <b-col class="d-flex justify-content-between align-items-center flex-wrap p-1">
            <div class="container-mode-views">
              <div class="color-gray-button responsive-button" v-if="checked_proposal.length !== 0">
                <span class="ml-2">{{$t('creator.selected_proposals')}}: {{checked_proposal.length}}</span>
              </div>
            </div>
            <div class="d-flex align-items-center flex-wrap ml-2 mb-1 mb-sm-0">
              <b-button @click="unarhived = !unarhived" variant="flat-secondary">
                <div class="d-flex align-items-center">
                  <feather-icon icon="EyeIcon" class="mr-05"></feather-icon>
                  <span>
                    {{ unarhived ? $t('creator.see_archived') : $t('creator.see_unarchived') }}
                  </span>
                </div>
              </b-button>
              <b-dropdown variant="flat-secondary" :text="$t('campaigns.actions')">
                <b-dropdown-item :disabled="checked_proposal.length === 0" @click="archivedProposals('')">
                  <div class="d-flex align-items-center">
                    <feather-icon icon="ArchiveIcon" class="mr-50" v-if="unarhived"/> 
                    <b-icon v-else icon="arrow-up-square" class="mr-50 arrow-up-square-icon"></b-icon>
                    <span>{{unarhived ? $t('tracking.archiving') : $t('tracking.unarchiving')}}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>         
        </b-card>
      </b-col>
      <b-col>
        <div class="scrollable-table">
          <b-table
            :items="unarhived ? getFormatTable().filter(i => i.status !== 'archived') : getFormatTable().filter(i => i.status === 'archived')"
            id="table-searcher"
            :fields="$t(`creator.table_proposals.proposals`)"
            head-variant="light"
            @row-clicked="rowTableClick"
            :key="change_proposals"
            class="h-table"
            :current-page="current_page"
            :per-page="per_page"
          >
            <template #head(id)>
              <b-form-checkbox v-model="selected_all"></b-form-checkbox>
            </template>
            <template #cell(id)="data">
            <div class="position-relative">
              <div class="d-flex justify-content-between position-absolute">
                <b-form-checkbox
                  class="checkbox-table-searcher d-none position-absolute-checkbox-searcher"
                  v-model="checked_proposal"
                  :value="data.item.id.uuid"
                  :ref="`checkbox_table_${data.item.id.id}`"
                >
                </b-form-checkbox>
                <h6 class="id-table-searcher" :ref="`id_table_${data.item.id.id}`">{{data.item.id.id}}</h6>
              </div>
            </div>

            </template>
            <template #cell(proposal)="data">
              <div class="d-flex align-items-center">
                <b-avatar-group>
                  <b-avatar  
                    button
                    class="avatar-conection-proposal"
                    variant="ligth">
                    <i v-if="data.item.network !== 'blog' && data.item.network !== 'twitter'" :class="`${utils.getClassNetworkColor(data.item.network)} icon-network-connection`" />
                    <b-img v-else-if="data.item.network === 'blog'" :src="utils.getClassNetworkColor(data.item.network)" fluid></b-img>
                    <div v-else-if="data.item.network === 'twitter'" class="icon-content-twitter-myProp">
                      <b-img :src="utils.getClassNetworkColor(data.item.network)" fluid class="icon-network-twitter-myProp"/>
                    </div>
                  </b-avatar>
                  <b-avatar
                    :src="data.item.proposal.avatar"
                    class="avatar-conection-proposal"
                    :icon="utils.isVideo(data.item.proposal.avatar) ? 'camera-video' : ''"
                  />
                </b-avatar-group>
                <div class="ml-1">
                  <strong class="text-overflow-proposals">{{data.item.proposal.name}}</strong>
                  <p class="text-muted m-0">
                    {{$t(`creator.type_of_work`).find((type) => type.value === data.item.proposal.work_type).text}}
                  </p>
                </div>
              </div>
            </template>
            <template
              #cell(status)="data"
            >
              <b-badge :variant="getVariantStatus(data.item.status)" :class="getVariantStatus(data.item.status)">
                {{$t(`creator.proposals_state.${data.item.status}`)}}
              </b-badge>
            </template>
            <template
              #cell(updated_at)="data"
            >
            <strong>{{data.item.updated_at}}</strong>
            </template>
            <template
              #cell(actions)="data"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  dropright  
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="editProposalAction(data.item)">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span>{{$t('lists.edit')}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="unPublished(data.item.id.uuid)" v-if="data.item.status === 'published'">
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span>{{$t('creator.unpublished')}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="archivedProposals(data.item.id.uuid)">
                    <feather-icon icon="ArchiveIcon" class="mr-50" v-if="data.item.status !== 'archived'"/> 
                    <b-icon v-else icon="arrow-up-square" class="mr-50 arrow-up-square-icon"></b-icon>
                    <span>{{data.item.status !== 'archived' ? $t('tracking.archiving') : $t('tracking.unarchiving')}}</span>
                  </b-dropdown-item>
                  <b-dropdown-item disabled>
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>{{$t('creator.delete')}}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </template>
          </b-table>
          <b-pagination 
            v-model="current_page" 
            :total-rows="getFormatTable().length" 
            :per-page="per_page"
            class="d-flex justify-content-center"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
    <div class="container-loader-searcher loader-table-cards" v-if="!loader_proposals">
      <b-spinner class="spinner-table-searcher"></b-spinner>
    </div>
    <template v-if="info_table.length === 0">
      <no-Results
        :title= "$t('creator.messageNoResultsProposal')"
        :text_button="$t('dashboard.createProposal')"
        :btn_action="createProposal"
        :img_general="require('@/assets/images/svg/megafono.svg')"/>
    </template>
  </div>
</template>

<script>
import {
  BImg,
  BSpinner,
  BButton,
  BCol,
  BRow,
  BCard,
  BTable,
  BFormCheckbox,
  BBadge,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BAvatarGroup,
  BPagination,
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';
import utils from '@/libs/utils';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import noResults from '@/views/pages/proposals/stepper/noResults.vue';


export default {
  name: 'myProposals',
  components: {
    BImg,
    BSpinner,
    noResults,
    BCard,
    BCol,
    BRow,
    BTable,
    BFormCheckbox,
    BBadge,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BAvatarGroup,
    BPagination,
    BButton,
  },
  data() {
    return {
      loader_proposals: false,
      selected_all: null,
      checked_proposal: [],
      info_table: [],
      utils,
      active_proposals: 0,
      new_orders: 0,
      in_review: 0,
      potential_earnings: 0,
      change_proposals: false,
      waiting_buyer: 0, 
      new_collabs: 0,
      changes_required: 0,
      pending_paid: 0,
      current_page: 1,
      per_page: 10,
      unarhived: true,
    }
  },
  created() {
    this.getProposals();
    this.getMyWorkSummary();
  },
  methods: {
    editProposalAction(proposal) {
      if (proposal.status !== 'published') this.$router.push({name: 'view_proposal', params: {slug: proposal.id.slug}})
      else this.$router.push({name: 'view_proposal', params: {slug: proposal.id.slug, is_editing: true}})
    },
    createProposal() {
      this.$router.push({ name: 'steps_proposal', params: {step: 'category'}})
    },
    checkProposalPending(status) {
      return status === 'pending'
    },
    submitPreview(proposal_uuid, status) {
      if (status === 'unpublished') {
        this.$swal({
          title: this.$t('creator.reviewAdminProposal'),
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: this.$t('creator.buttonPublish'),
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false,
          heightAuto: false
        }).then(result => {
          if (result.value) {
            proposals_services.sendReview(proposal_uuid).then(response => {
              if (response.updated !== undefined) {
                const proposal = this.info_table.find(item => item.uuid === proposal_uuid)
                if (response.updated) {
                  this.change_proposals = !this.change_proposals
                  proposal.state = 'in_review'
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: this.$t('creator.proposal_in_review_title'),
                      icon: "BellIcon",
                      variant: "success",
                      text: this.$t('creator.proposal_in_review_text'),
                    },
                  });
                }
              }
            });
          }
        });
      } else {
        proposals_services.sendReview(proposal_uuid).then(response => {
          if (response.updated !== undefined) {
            const proposal = this.info_table.find(item => item.uuid === proposal_uuid)
            if (response.updated) {
              this.change_proposals = !this.change_proposals
              proposal.state = 'in_review'
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t('creator.proposal_in_review_title'),
                  icon: "BellIcon",
                  variant: "success",
                  text: this.$t('creator.proposal_in_review_text'),
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$t('campaigns.errormessage'),
                  icon: "AlertIcon",
                  variant: "danger",
                  text: this.$t('creator.proposal_in_review_error'),
                },
              });
            }
          }
        })
      }
    },
    unPublished(proposal_uuid) {
      proposals_services.unPublished(proposal_uuid).then(response => {
        if (response.state === "unpublished") {
          const proposal = this.info_table.find(item => item.uuid === proposal_uuid)
          if (proposal) {
            proposal.state = 'unpublished'
          }
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$t('creator.titleUnpublished'),
              icon: "BellIcon",
              variant: "success",
              text: this.$t('creator.textUnpublished'),
            },
          });
        } 
      })
    },
    getVariantStatus(status) {
      if (status === 'in_review' || status === 'changes_required') return 'light-warning'
      if (status === 'published') return 'light-success'
      if (status === 'archived' || status === 'unpublished') return 'light-danger'
      return 'light-secondary'
    },
    getMyWorkSummary() {
      proposals_services.myWorkSummary().then(response => {
        this.active_proposals = response.active_proposals;
        this.in_review = response.in_review_proposals;
        this.new_orders = response.orders;
        this.potential_earnings = utils.decimalFixed(response.potential_earnings, 2)
        this.waiting_buyer = response.waiting_buyer;
        this.new_collabs = response.new_collabs;
        this.changes_required = response.changes_required;
        this.pending_paid = utils.decimalFixed(response.pending_paid, 2);
      })
    },
    getProposals() {
      proposals_services.getProposals().then((response) => {
        this.info_table = response.results;
        this.loader_proposals = true
      })
    },
    getFormatTable() {
      const data = [];
      this.info_table.map((item, index) => {
        let first_image = {}
        if (item.images.length > 0) {
          first_image = item.images.reduce((prev, curr) => {
            return prev.sort < curr.sort ? prev : curr;
          });
        }
        data.push(
          {
            id: {id: index + 1, slug: item.slug, uuid: item.uuid}, 
            proposal: {
              name: item.title,
              work_type: item.work_type,
              avatar: item.images.length > 0 ? !first_image.media ? utils.getAssetUrl(first_image.media) : first_image.media : require("@/assets/images/dashboard/imgDefault.png")
            }, 
            status: item.state,
            network: item.user_network.network,
            updated_at: utils.getDatev2(new Date(item.user_network.updated_at)),
          });
      });
      return data;
    },
    rowTableClick(row) {
      this.$router.push({name: 'view_proposal', params: {slug: row.id.slug}})
    },
    checkCheckboxClasses(val) {
      if (val.length > 0) {
        for (let i = 1; i <= this.info_table.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const id_table = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              checkbox.$el.classList.remove('d-none');
              checkbox.$el.classList.remove('checkbox-table-searcher');
            }
            if (id_table) id_table.classList.add('d-none');
          });
        }
      } else {
        for (let i = 1; i <= this.info_table.length; i++) {
          this.$nextTick(() => {
            const checkbox = this.$refs[`checkbox_table_${i}`];
            const id_table = this.$refs[`id_table_${i}`];
            
            if (checkbox && checkbox.$el) {
              checkbox.$el.classList.add('d-none');
              checkbox.$el.classList.add('checkbox-table-searcher');
            }
            if (id_table) id_table.classList.remove('d-none');
          });
        }
      }
    },
    checkedAll() {
      this.checked_proposal = [];
      let in_array = 0;
      this.info_table.forEach((item, index) => {
        if ((in_array < this.per_page) && (index <= (this.per_page * this.current_page) && index >= (this.per_page * (this.current_page - 1)))) {
          this.checked_proposal.push(item.uuid)
          in_array += 1
        }
      });
    },
    archivedProposals(unique_uuid = '') {
      this.$swal({
        title: this.unarhived ? this.$t('creator.delete_proposal_title') : this.$t('creator.unarchive_proposal_title'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.unarhived ? this.$t('creator.confirm_button_delete') : this.$t('creator.confirm_button_desarchive'),
        customClass: {
          confirmButton: 'btn blue-button',
          cancelButton: 'btn outline-blue ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          const proposals_uuid = unique_uuid ? [unique_uuid] : this.checked_proposal
          proposals_services.deleteProposals({proposals: proposals_uuid}).then(() => {
            this.checked_proposal = [];
            proposals_uuid.map((proposal_uuid) => {
              const proposal = this.info_table.find(item => item.uuid === proposal_uuid)

              if (proposal) {
                proposal.state = this.unarhived ? 'archived' : 'pending'
              }
            });
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t('creator.deleted_proposals'),
                icon: "AlertIcon",
                variant: "danger",
                text: this.$t('creator.deleted_proposals_text'),
              },
            });
          });
        }
      });
    }
  },
  watch: {
    checked_proposal(val) {
      this.checkCheckboxClasses(val);
      if (this.checked_proposal.length === 0) this.selected_all = false
      else if (this.checked_proposal.length === this.info_table.length) this.selected_all = true
    },
    selected_all(val) {
      if (val) this.checkedAll();
      else this.checked_proposal = [];
    },
    current_page() {
      this.selected_all = null;
    }
  }
}
</script>

<style scoped>
.text-overflow-proposals {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
 .avatar-conection-proposal {
  width: 2.5rem !important;
  height: 2.5rem !important;
 }
 .h-table {
  height: 155px; 
 }
 .container-loader-searcher {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255);
    z-index: 1000;
    right: 5px;
  }
  .loader-table-cards {
    z-index: 10;
    display: flex;
    justify-content: center;
  }
  .spinner-table-searcher {
    margin-top: 14em;
    height: 6em;
    width: 6em;
    color: #acacac;
  }
  .background-left-proposal {
    width: 10px;
    height: 220px;
    position: absolute;
    top: 0;
    left: 0px;
  }
  .title-tools{
    font-weight: bolder;
    color: #1ABC9C;
  }
  .title-orders{
    font-weight: bolder;
    color: #9B59B6;
  }
  .title-admin-changes{
    font-weight: bolder;
    color: #D68910;
  }
  .title-potential{
    font-weight: bolder;
    color: #EC7063;
  }
  .default-color-background-tools {
    background: linear-gradient(180deg, #50D0AD 0%, #29809A 100%);
  }
  .default-color-background-orders {
    background: linear-gradient(180deg, #ef98ff 0, #bf83ff 25%, #8f6cd8 50%, #6355b0 75%, #3d3f8b 100%);
  }
  .default-color-background-requests {
    background: radial-gradient(circle at 50% -20.71%, #e7e14a 0, #e4cf42 12.5%, #dcbc3c 25%, #d2a736 37.5%, #c39231 50%, #b37e2d 62.5%, #a46c29 75%, #965c26 87.5%, #894f24 100%);
  }
  .default-color-background-earnings {
    background: radial-gradient(circle at 50% -20.71%, #ff7e6b 0, #ff6e73 12.5%, #ff5d78 25%, #ff4c7a 37.5%, #f23c78 50%, #d52e74 62.5%, #ba2471 75%, #a21d6e 87.5%, #8d1a6c 100%);
  }
  .light-secondary-v2 {
    background-color: #EFF3F6;
    color: #718096;
    font-weight: 400;
  }
  .avatar-proposal-table {
    margin: 0 1em 0 0;
    border-radius: 1em !important;
  }
  #table-searcher-card .card-body {
    padding: 0;
  }
  .table-responsive {
    overflow: visible;
  }
  #table-searcher td {
    border-bottom: 1px solid #eff3f6;
  }
  #table-searcher tr {
    background-color: white;
  }
  #table-searcher tr:hover {
    background-color: #EFF3F6;
    cursor: pointer;
    transition: all 100ms;
  }
  .thead-light th {
    background-color: white !important;
  }
  .checkbox-table-searcher {
    display: none !important;
  }
  #table-searcher tr:hover .checkbox-table-searcher {
    display: block !important;
  }
  #table-searcher tr:hover .id-table-searcher {
    display: none;
  }
  .mr-05 {
    margin-right: 0.5em;
  }
  .card-shadow:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .card-shadow {
    width: 100%;
  }
  .icon-network-twitter-myProp {
  width: 65%;
  height: 65%;
}
.icon-content-twitter-myProp {
  display: flex;
  background: black;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
</style>
<style scoped lang="scss">
.arrow-up-square-icon {
  height: 14px;
  width: 14px;
}
</style>