import axios from 'axios'
import config from '@/services/config'
import { tokenExpired } from '@/auth/utils'

export default {
  getTransactions(type_transaction = false) {
    const param = type_transaction ? `?type_transaction=${type_transaction}&order_by=-paid_at` : '?order_by=-paid_at';
    return axios.get(`${config.urlCore}/api/v3/finance/transactions/${param}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
      })
      .then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired()
        return error.response
      })
  },
}
